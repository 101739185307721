<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="reloadReport"> 종합영업일보1 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="reloadReport2"> 종합영업일보2 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="reloadReport3"> 종합영업일보3 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="reloadReport4">
            종합영업일보 - popup
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="reloadReport5">
            단체매출 테스트
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="memoryTest1">
            메모리 부하 테스트 - iframe
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="memoryTest2">
            메모리 부하 테스트 - Popup
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="memoryTest3"> 회원정보 등록 </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button button-div="GET" @click.native="printReport"> 외부에서 프린트 </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="false" />
      <ReportView ref="reportViewComponent2" :isPopup="true" />
    </div>
  </div>
</template>

<style scoped></style>

<script>
import ReportView from "@/components/common/report/ReportView";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ReportSampleView",
  components: {
    ReportView,
    ErpButton,
  },
  data() {
    return {
      reportJsonDataSample: {
        bsnDate: "2019-12-01",
        dayOfWeekColor: "#2f55f8",
        weekCaption: "주말",
        weekCaptionColor: "#2f55f8",
        closeDiv: "Y",
        sanctnLineList: [
          {
            dtlId: 6,
            headerName: "부회장.",
          },
          {
            dtlId: 1,
            headerName: "담  당",
          },
          {
            dtlId: 2,
            headerName: "과  장",
          },
          {
            dtlId: 3,
            headerName: "부  장",
          },
          {
            dtlId: 4,
            headerName: "임  원",
          },
          {
            dtlId: 5,
            headerName: "부사장.",
          },
        ],
        closePaymtList: [
          {
            paymtCode: "100",
            paymtCodeName: "심포니회원",
            visit9hPer: "0",
            visit18hPer: "11",
            visit9hAditPer: "0",
            visitTotPer: "11",
            visitMonthPer: "0",
            visitYearPer: "11",
          },
          {
            paymtCode: "110",
            paymtCodeName: "소나타회원",
            visit9hPer: "0",
            visit18hPer: "8",
            visit9hAditPer: "0",
            visitTotPer: "8",
            visitMonthPer: "0",
            visitYearPer: "8",
          },
          {
            paymtCode: "120",
            paymtCodeName: "앙상블회원",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "130",
            paymtCodeName: "미뉴에트회원",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "140",
            paymtCodeName: "오케스트라회원",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "NOM",
            paymtCodeName: "비회원",
            visit9hPer: "0",
            visit18hPer: "28",
            visit9hAditPer: "0",
            visitTotPer: "28",
            visitMonthPer: "0",
            visitYearPer: "28",
          },
          {
            paymtCode: "WEB",
            paymtCodeName: "인터넷",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "DAY",
            paymtCodeName: "일계",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "MONTH",
            paymtCodeName: "월계",
            visit9hPer: "0",
            visit18hPer: "0",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "0",
          },
          {
            paymtCode: "YEAR",
            paymtCodeName: "년계",
            visit9hPer: "0",
            visit18hPer: "47",
            visit9hAditPer: "0",
            visitTotPer: "0",
            visitMonthPer: "0",
            visitYearPer: "47",
          },
        ],
        monthlyBsnDateInfo: {
          weekdayBsnDay: 0,
          weekendBsnDay: 1,
          totBsnDay: 1,
        },
        weeklyReservationStatusList: [
          {
            bsnDate: "2019-12-02",
            dwName: "월",
            resveAbleTeam: 0,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-03",
            dwName: "화",
            resveAbleTeam: 0,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-04",
            dwName: "수",
            resveAbleTeam: 120,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-05",
            dwName: "목",
            resveAbleTeam: 118,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-06",
            dwName: "금",
            resveAbleTeam: 0,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-07",
            dwName: "토",
            resveAbleTeam: 0,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
          {
            bsnDate: "2019-12-08",
            dwName: "일",
            resveAbleTeam: 0,
            resveTeam: 0,
            resveRate: 0.0,
            resveFitTeam: 0,
            resveGrpTeam: 0,
          },
        ],
        visitTeamCntAndPerTeamList: [
          {
            acmtlSumDiv: "DD",
            acmtlSumDivName: "당일",
            visitTeam: 0,
            teamAvgPer: 0.0,
          },
          {
            acmtlSumDiv: "MM",
            acmtlSumDivName: "월계",
            visitTeam: 0,
            teamAvgPer: 0.0,
          },
          {
            acmtlSumDiv: "YY",
            acmtlSumDivName: "년계",
            visitTeam: 17,
            teamAvgPer: 2.76,
          },
        ],
        closeAcmtlSalesList: [
          {
            acmtlSumDiv: "DD",
            acmtlSumDivName: "당일",
            feeSalesAmt: 0,
            cartSalesAmt: 0,
            rentSalesAmt: 0,
            foodSalesAmt: 0,
            shopSalesAmt: 0,
            etcSalesAmt: 0,
            totSalesAmt: 0,
            totTaxAmt: 0,
            feeRentSalesUnitPrice: 0,
            foodSalesUnitPrice: 0,
            shopSalesUnitPrice: 0,
            totSalesUnitPrice: 0,
          },
          {
            acmtlSumDiv: "MM",
            acmtlSumDivName: "월계",
            feeSalesAmt: 0,
            cartSalesAmt: 0,
            rentSalesAmt: 0,
            foodSalesAmt: 0,
            shopSalesAmt: 0,
            etcSalesAmt: 0,
            totSalesAmt: 0,
            totTaxAmt: 0,
            feeRentSalesUnitPrice: 0,
            foodSalesUnitPrice: 0,
            shopSalesUnitPrice: 0,
            totSalesUnitPrice: 0,
          },
          {
            acmtlSumDiv: "YY",
            acmtlSumDivName: "년계",
            feeSalesAmt: 3255880,
            cartSalesAmt: 1163642,
            rentSalesAmt: 63637,
            foodSalesAmt: 372547,
            shopSalesAmt: 100000,
            etcSalesAmt: 0,
            totSalesAmt: 4955706,
            totTaxAmt: 877094,
            feeRentSalesUnitPrice: 95386,
            foodSalesUnitPrice: 7927,
            shopSalesUnitPrice: 2128,
            totSalesUnitPrice: 105441,
          },
        ],
        todayFeeInfo: {
          norFeeAmt: 0,
          totFeeAmt: 0,
          dcFeeAmt: 0,
        },
        closeAcmtlBasicList: [
          {
            acmtlSumDiv: "DD",
            acmtlSumDivName: "당일",
            payCash: 0,
            payCard: 0,
            payDeposit: 0,
            payPrecard: 0,
            payCredit: 0,
            payGiftTrans: 0,
            payPoint: 0,
            payTotAmt: 0,
            creditReturnCash: 0,
            creditReturnCard: 0,
            creditReturnDeposit: 0,
            creditReturnPrecard: 0,
            creditReturnTrans: 0,
            creditReturnTotAmt: 0,
          },
          {
            acmtlSumDiv: "MM",
            acmtlSumDivName: "월계",
            payCash: 0,
            payCard: 0,
            payDeposit: 0,
            payPrecard: 0,
            payCredit: 0,
            payGiftTrans: 0,
            payPoint: 0,
            payTotAmt: 0,
            creditReturnCash: 0,
            creditReturnCard: 0,
            creditReturnDeposit: 0,
            creditReturnPrecard: 0,
            creditReturnTrans: 0,
            creditReturnTotAmt: 0,
          },
          {
            acmtlSumDiv: "YY",
            acmtlSumDivName: "년계",
            payCash: 2184000,
            payCard: 0,
            payDeposit: 0,
            payPrecard: 0,
            payCredit: 0,
            payGiftTrans: 0,
            payPoint: 0,
            payTotAmt: 2184000,
            creditReturnCash: 0,
            creditReturnCard: 0,
            creditReturnDeposit: 0,
            creditReturnPrecard: 0,
            creditReturnTrans: 0,
            creditReturnTotAmt: 0,
          },
        ],
        closeCard: {
          cardName1: "현대카드사",
          cardName2: "테스트 매입처",
          cardName3: "롯데카드사",
          cardName4: "신한카드사",
          cardName5: "BC카드사",
          cardName6: "우리카드",
          cardName7: "삼성카드사",
          cardName8: "외환카드사",
          cardName9: "NH카드사",
          totCardName: "●카드매출",
          approvalCnt1: 10,
          approvalCnt2: 20,
          approvalCnt3: 30,
          approvalCnt4: 40,
          approvalCnt5: 50,
          approvalCnt6: 60,
          approvalCnt7: 70,
          approvalCnt8: 80,
          approvalCnt9: 90,
          totApprovalCnt: 450,
          approvalAmt1: 5,
          approvalAmt2: 10,
          approvalAmt3: 15,
          approvalAmt4: 20,
          approvalAmt5: 25,
          approvalAmt6: 30,
          approvalAmt7: 35,
          approvalAmt8: 40,
          approvalAmt9: 45,
          totApprovalAmt: 70,
        },
      },
      groupSalesTest: {
        salesReportByGroupList: [
          {
            grpKind: "NOR",
            grpNo: "B041",
            grpName: "고려대부천",
            grpInfo: "일반 (B041) 고려대부천",
            bsnDate: "2020-08-30",
            dwCode: "1",
            bsnCode: "WEEKEND",
            startTime: "07:00",
            teamCnt: 4.0,
            aditTeam: 1.0,
            memberPer: 0.0,
            nomemberPer: 1600.0,
            totPer: 16.0,
            totPerHole: null,
            totSalesAmt: 4391500.0,
            totSalesUnitPrice: 274469,
            netSalesAmt: 3992251.0,
            netSalesUnitPrice: 249516,
            feeAmt: 3200000.0,
            cartAmt: 320000.0,
            feeCartTotAmt: 3520000,
            feeCartTotUnitPrice: 220000,
            feeNetAmt: 2909088.0,
            cartNetAmt: 290896.0,
            feeCartTotNetAmt: 3199984,
            feeCartTotNetUnitPrice: 199999,
            rentAmt: 0.0,
            rentUnitPrice: 0,
            rentNetAmt: 0.0,
            rentNetUnitPrice: 0,
            grillAmt: 464000.0,
            teeAmt: 357500.0,
            grillteeTotAmt: 821500,
            grillteeTotUnitPrice: 51344,
            grillNetAmt: 421818.0,
            teeNetAmt: 324996.0,
            grillteeTotNetAmt: 746814,
            grillteeTotNetUnitPrice: 46676,
            shopAmt: 50000.0,
            shopUnitPrice: 3125,
            shopNetAmt: 45453.0,
            shopNetUnitPrice: 2841,
            etcAmt: 0.0,
            etcUnitPrice: 0,
            etcNetAmt: 0.0,
            etcNetUnitPrice: 0,
          },
          {
            grpKind: "YEAR",
            grpNo: "1319",
            grpName: "GF회",
            grpInfo: "연단체 (1319) GF회",
            bsnDate: "2019-04-19",
            dwCode: "6",
            bsnCode: "WEEKDAY",
            startTime: "07:30",
            teamCnt: 1.0,
            aditTeam: 2.0,
            memberPer: 0.0,
            nomemberPer: 0.0,
            totPer: 0.0,
            totPerHole: null,
            totSalesAmt: 530000.0,
            totSalesUnitPrice: 0,
            netSalesAmt: 461256.0,
            netSalesUnitPrice: 0,
            feeAmt: 450000.0,
            cartAmt: 80000.0,
            feeCartTotAmt: 530000,
            feeCartTotUnitPrice: 0,
            feeNetAmt: 388528.0,
            cartNetAmt: 72728.0,
            feeCartTotNetAmt: 461256,
            feeCartTotNetUnitPrice: 0,
            rentAmt: 0.0,
            rentUnitPrice: 0,
            rentNetAmt: 0.0,
            rentNetUnitPrice: 0,
            grillAmt: 0.0,
            teeAmt: 0.0,
            grillteeTotAmt: 0,
            grillteeTotUnitPrice: 0,
            grillNetAmt: 0.0,
            teeNetAmt: 0.0,
            grillteeTotNetAmt: 0,
            grillteeTotNetUnitPrice: 0,
            shopAmt: 0.0,
            shopUnitPrice: 0,
            shopNetAmt: 0.0,
            shopNetUnitPrice: 0,
            etcAmt: 0.0,
            etcUnitPrice: 0,
            etcNetAmt: 0.0,
            etcNetUnitPrice: 0,
          },
          {
            grpKind: "YEAR",
            grpNo: "1319",
            grpName: "GF회",
            grpInfo: "연단체 (1319) GF회",
            bsnDate: "2019-05-07",
            dwCode: "3",
            bsnCode: "WEEKDAY",
            startTime: "07:15",
            teamCnt: 6.0,
            aditTeam: 6.0,
            memberPer: 0.0,
            nomemberPer: 0.0,
            totPer: 0.0,
            totPerHole: null,
            totSalesAmt: 298800.0,
            totSalesUnitPrice: 0,
            netSalesAmt: 230965.0,
            netSalesUnitPrice: 0,
            feeAmt: 181000.0,
            cartAmt: 80000.0,
            feeCartTotAmt: 261000,
            feeCartTotUnitPrice: 0,
            feeNetAmt: 123873.0,
            cartNetAmt: 72728.0,
            feeCartTotNetAmt: 196601,
            feeCartTotNetUnitPrice: 0,
            rentAmt: 0.0,
            rentUnitPrice: 0,
            rentNetAmt: 0.0,
            rentNetUnitPrice: 0,
            grillAmt: 37800.0,
            teeAmt: 0.0,
            grillteeTotAmt: 37800,
            grillteeTotUnitPrice: 0,
            grillNetAmt: 34364.0,
            teeNetAmt: 0.0,
            grillteeTotNetAmt: 34364,
            grillteeTotNetUnitPrice: 0,
            shopAmt: 0.0,
            shopUnitPrice: 0,
            shopNetAmt: 0.0,
            shopNetUnitPrice: 0,
            etcAmt: 0.0,
            etcUnitPrice: 0,
            etcNetAmt: 0.0,
            etcNetUnitPrice: 0,
          },
          {
            grpKind: "YEAR",
            grpNo: "Z676",
            grpName: "G 다공회",
            grpInfo: "연단체 (Z676) G 다공회",
            bsnDate: "2019-06-18",
            dwCode: "3",
            bsnCode: "WEEKDAY",
            startTime: "06:07",
            teamCnt: 3.0,
            aditTeam: 7.0,
            memberPer: 0.0,
            nomemberPer: 0.0,
            totPer: 0.0,
            totPerHole: null,
            totSalesAmt: 950000.0,
            totSalesUnitPrice: 0,
            netSalesAmt: 801948.0,
            netSalesUnitPrice: 0,
            feeAmt: 790000.0,
            cartAmt: 160000.0,
            feeCartTotAmt: 950000,
            feeCartTotUnitPrice: 0,
            feeNetAmt: 656492.0,
            cartNetAmt: 145456.0,
            feeCartTotNetAmt: 801948,
            feeCartTotNetUnitPrice: 0,
            rentAmt: 0.0,
            rentUnitPrice: 0,
            rentNetAmt: 0.0,
            rentNetUnitPrice: 0,
            grillAmt: 0.0,
            teeAmt: 0.0,
            grillteeTotAmt: 0,
            grillteeTotUnitPrice: 0,
            grillNetAmt: 0.0,
            teeNetAmt: 0.0,
            grillteeTotNetAmt: 0,
            grillteeTotNetUnitPrice: 0,
            shopAmt: 0.0,
            shopUnitPrice: 0,
            shopNetAmt: 0.0,
            shopNetUnitPrice: 0,
            etcAmt: 0.0,
            etcUnitPrice: 0,
            etcNetAmt: 0.0,
            etcNetUnitPrice: 0,
          },
        ],
      },
    };
  },
  mounted() {
    this.reloadReport();
  },
  methods: {
    reloadReport() {
      const reportData = {
        reportJson: this.reportJsonDataSample,
        searchParams: {
          memberName: "테스트", // 파라미터
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, "DailyReport");
    },
    reloadReport2() {
      // 영업일수 변경
      const reportData = JSON.parse(
        JSON.stringify({ reportJson: this.reportJsonDataSample })
      );
      reportData.reportJson.monthlyBsnDateInfo = {
        weekdayBsnDay: 5144,
        weekendBsnDay: 1645,
        totBsnDay: 1023,
      };
      this.$refs.reportViewComponent.postReport(reportData, "DailyReport");
    },
    reloadReport3() {
      // 결재라인 변경
      const reportData = JSON.parse(
        JSON.stringify({ reportJson: this.reportJsonDataSample })
      );
      reportData.reportJson.sanctnLineList = [
        {
          dtlId: 6,
          headerName: "부회장.",
        },
        {
          dtlId: 1,
          headerName: "담  당",
        },
        {
          dtlId: 2,
          headerName: "과  장",
        },
        {
          dtlId: 3,
          headerName: "부  장",
        },
      ];
      this.$refs.reportViewComponent.postReport(reportData, "DailyReport");
    },
    reloadReport4() {
      // 카드 입금 현황 변경
      const reportData = JSON.parse(
        JSON.stringify({ reportJson: this.reportJsonDataSample })
      );
      reportData.reportJson.closeCard = {
        cardName1: "카뱅",
        cardName2: "우리뱅",
        cardName3: "SKT",
        totCardName: "●카드매출",
        approvalCnt1: 33,
        approvalCnt2: 44,
        approvalCnt3: 55,
        totApprovalCnt: 132,
        approvalAmt1: 6,
        approvalAmt2: 16,
        approvalAmt3: 333,
        totApprovalAmt: 355,
      };
      this.$refs.reportViewComponent2.postReport(reportData, "DailyReport");
    },
    reloadReport5() {
      const reportData = JSON.parse(
        JSON.stringify({ reportJson: this.groupSalesTest })
      );
      this.$refs.reportViewComponent.postReport(reportData, "GroupSalesTest");
    },
    memoryTest1() {
      this.$refs.reportViewComponent.postReport(
        null,
        "ReservationHistoryMemoryTest"
      );
    },
    memoryTest2() {
      this.$refs.reportViewComponent2.postReport(
        null,
        "ReservationHistoryMemoryTest"
      );
    },
    memoryTest3() {
      this.$refs.reportViewComponent.postReport(null, "MemberRegistration");
    },
    printReport() {
      this.$refs.reportViewComponent.printReport();
    },
  },
};
</script>
